import { NullableString, UUID } from './Types';

export default interface Location {
    companyId: UUID | null;
    rank: number | null;
    name: NullableString;
    streetAddress: NullableString;
    subAddress: NullableString;
    city: NullableString;
    county: NullableString;
    stateOrProvince: NullableString;
    postalCode: NullableString;
    region: NullableString;
    country: NullableString;
    phone: NullableString;
    hq: boolean;
    lat: number | null;
    long: number | null;
}

export const DefaultLocation: Location = {
    companyId: '00000000-0000-0000-0000-000000000000',
    rank: 0,
    name: null,
    streetAddress: null,
    subAddress: null,
    city: null,
    county: null,
    stateOrProvince: null,
    postalCode: null,
    region: null,
    country: 'USA',
    phone: null,
    hq: false,
    lat: null,
    long: null
}

export function isEmpty(location: Location): boolean {
    
    //let values = Object.values(loc);
    const keys = Object.keys(location);
    let populated = false;

    keys.forEach(k => {

        const itm = location as any
        const val = itm[k];

        switch (typeof val) {
            case 'string':
                if(k != 'country' && k != 'companyId' && val != '') { populated = true }
                break;
            case 'number':
                if(k != 'rank' && val != 0) { populated = true }
                break;
            default:
                break;
        }
        
        // // eslint-disable-next-line
        // console.log(`${typeof val} field ${k} is '${val}'`)
    });

    return !populated;
}